import { removeUrlProtocol } from "../../../../Utils/Functions";
import { useInstitucional } from "./hook";

export const Institucional = () => {
	const { data } = useInstitucional();

	return (
		<div className="institucional">
			{data?.map(({ url, name }, i) => (
				<a style={{ whiteSpace: 'nowrap', margin: "5px 0px", padding: "8px" }} key={"link_box_item_" + i} href={removeUrlProtocol(url)} title={name} target="_blank">
					{name}
				</a>
			))
			}
		</div >
	);
};
