export const useInstitucional = () => {
	return {
		data: [
			{
				id: 1,
				name: "Términos de uso",
				url: "https://www.infocasas.com.uy/faq#boxtc",
			},
			{
				id: 2,
				name: "Políticas de privacidad",
				url: "https://www.infocasas.com.uy/faq#boxpart",
			},
			{
				id: 3,
				name: "Preguntas frecuentes",
				url: "https://www.infocasas.com.uy/faq",
			},

		],
	};
};
