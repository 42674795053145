import { useQuery } from "@apollo/client";
import { QUERY_BANNERS_HEADER } from "../../../GQL/BannersHeader";
import { useState } from "react";

export const useBannersHeader = ({ where = "portada" }) => {
	const [randChance] = useState(Math.random())
	const {data} = useQuery(QUERY_BANNERS_HEADER, {
		ssr: true,
		variables: { whereToShow: where },
	});

	return setBannerType(data, where,randChance)
};

const setBannerType = (data, where,randChance) => {
	let type: "fixed" | "floting" | null = null;
	let banner = null;

	if (data && (data.bannerHeader != null || data.bannerShopping != null) ) {
		if (data.bannerHeader != null && data.bannerShopping == null) {
			// banner header
			type = "fixed";
		} else if (data.bannerHeader == null && data.bannerShopping != null) {
			// banner flotante
			if (where != "ampliar") type = "floting";
		} else {
			// random
			if (where == "ampliar") type = "fixed";
			else type = randChance < 0.5 ? "fixed" : "floting";
		}

		if (type != null) {
			banner = type == "fixed" ? data.bannerHeader : data.bannerShopping
		}
	}

	return {banner,type}
}

