import { useRouter } from "next/router";
import { BannerFlotante } from "./BannerFlotante/web";
import { BannerHeader } from "./BannerHeader/web";
import { useBannersHeader } from "./hook";
import { useFilters } from "shared-components/Components/Filters/Filters.hook";
import { useUser } from "shared-components/Components/User/User.hook";

export const BannersHeader = () => {
	const { pathname, asPath } = useRouter();
	const { filters } = useFilters()
	const { type, banner } = useBannersHeader({ where: getWhereFromPathname(pathname) });
	const { user } = useUser()

	if (type == null || banner == null || (filters && filters.page >= 2 && user.data.me) || asPath.startsWith('/listado')) return null;

	if (type == "floting") return <BannerFlotante data={banner} />;
	return <BannerHeader data={banner} />;
};

// aux
const getWhereFromPathname = (pathname: string) => {
	if (pathname.indexOf("home") >= 0) return "portada";
	else if (pathname.indexOf("search") >= 0) return "listar";
	else if (pathname.indexOf("prop") >= 0 || pathname.indexOf("project") >= 0) return "ampliar";
	return "portada";
};

