import "./styles.less";
import { ArrowIcon } from "shared-components/Components/CustomIcons/ArrowIcon/web";

export const BannerHeader = ({ data }) => {

	return (
		<>
			<a
				id="banner-header"
				href={data.link}
				target="_blank"
				style={{
					backgroundColor: data.html ? "#fff" : data.background_color,
					color: data.text_color,
				}}>
				{data.html ? (
					<iframe src={data.html} /> // 70px x 100% desktop y 92px x 100% mobile
				) : (
					<div className="contenedor">
						{/* 700 x 70 */}
						<img src={data.image} className="banner" />
						<div
							className="right-content"
							style={{
								backgroundColor: data.background_color,
								color: data.text_color,
							}}>
							<div className="texto">
								<div
									className="top"
									style={{ color: data.text_color }}>
									{data.title}
								</div>
								{/* 50 chars */}
								<div
									className="bottom"
									style={{ color: data.text_color }}>
									{data.text}
								</div>
							</div>
							<div
								className="btn desktop"
								style={{
									backgroundColor: data.button_background_color,
									color: data.button_text_color,
								}}>
								{data.button_text}
							</div>
							<div
								className="btn mobile"
								style={{
									backgroundColor: data.button_background_color,
									color: data.button_text_color,
								}}>
								<ArrowIcon />
							</div>
						</div>
					</div>
				)}
			</a>
			<style jsx>{`
				#banner-header:hover .btn {
					background-color: ${data.button_text_color} !important;
					color: ${data.button_background_color} !important;
				}
			`}</style>
		</>
	);
};

